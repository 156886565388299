import text_black from '../../assets/love_text_black.png'
import text_white from '../../assets/love_text_white.png'
import love_white from '../../assets/love_white.png'
import love_black from '../../assets/love_black.png'
import love_text from '../../assets/logo_text.png'

import group from '../../assets/group.png'


import back2 from '../../assets/back2.svg'
import grpImg1 from '../../assets/group2.svg'
import grpImg2 from '../../assets/group201.svg'


export const textBlacks = text_black
export const textWhites = text_white
export const LogoWhites = love_white 
export const Logo_text = love_text 
export const LogoBlacks = love_black
export const back2s = back2
export const groups = group
export const grpImg1s = grpImg1
export const grpImg2s = grpImg2
 

