import React, { Fragment} from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Hero'

function Home() {
  return (
    <Fragment>
      <Hero BigText="Privacy and Policy"  />
      <div className="flex justify-center items-center my-24">
        <div className="flex-wrap justify-center items-center px-6 md:px-32 xl:px-64 space-y-12">
          {/* intro title  */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-3xl">
                Privacy Policy for LoveAfrica
              </div>

              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica is a dating App that targets young and unmarried
                  adults who seek to find affectionate partners. These terms of
                  use Apply to how registered members utilize the LoveAfrica
                  App. Users must comply with these terms and conditions to
                  avoid possible legal and social consequences. These terms and
                  conditions represent the contract between registered users
                  (hereafter, you) and LoveAfrica (henceforth, the App).
                </p>

                <p>
                  LoveAfrica Inc. is the owner and manager of the App and
                  provides digital products to those who intend to find life
                  partners. These terms and conditions legally bind those who
                  register and use the App.
                </p>
              </div>
            </div>
          </div>

          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Acceptance of Terms and Conditions
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. reserves the right to regularly change and
                  improve terms and conditions and privacy policy. This updating
                  process can allow LoveAfrica Inc. management to ensure that
                  the App meets dynamic social needs and government regulations.
                  Other factors that may prompt a change of terms and conditions
                  include anticipated future changes and active business
                  practices.
                </p>

                <p>
                  You can access the current terms and conditions on the App
                  with the last modification dates. You should check terms and
                  conditions regularly because changes and modifications become
                  effective upon publication in the App. Your continued App use
                  after updating terms and conditions indicates users’ consent.
                  However, you will always receive a notification after updated
                  terms and conditions.
                </p>
              </div>
            </div>
          </div>

          {/* eligibility */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">Eligibility</div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  You should meet the following conditions despite being
                  unmarried and over 18 years of age.
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">
                      You are willing to enter a binding contract with
                      LoveAfrica Inc.
                    </li>

                    <li className="pl-3">
                      You do not have an ongoing legal case or court order
                      relating to sexual assault, violence, misconduct, and
                      harassment in your country.
                    </li>

                    <li className="pl-3">
                      You are not a former user of the App who faced fraud
                      charges previously.
                    </li>

                    <li className="pl-3">
                      You are not a registered citizen in an African country. In
                      other words, Africans all over the world may use apps.
                    </li>

                    <li className="pl-3">
                      You are willing to comply with this agreement and other
                      laws Applicable to local and national laws Applicable in
                      your country.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* types of content */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Types of Content
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  You will access three types of content on the App. Each type
                  of information has user- specific rules and regulations that
                  you must observe. The following are specific types of
                  information that you will access and use.
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">
                      “Your content” refers to the content you create and share
                      through the App.
                    </li>

                    <li className="pl-3">
                      “Member content” refers to App users; information in their
                      timelines, profiles, and galleries.
                    </li>

                    <li className="pl-3">
                      “Our content” – this information includes content provided
                      by LoveAfrica Inc.
                    </li>
                  </ul>
                </p>
              </div>

              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. reserves the right to prohibit and remove
                  content that disregards users’ dignity. These rules and limits
                  allow users to express themselves effectively without fear of
                  intimidation. Some of the content that you cannot share
                  through the App include:
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">
                      Offensive messages and images can embarrass, annoy, alarm,
                      or upset other users.
                    </li>

                    <li className="pl-3">
                      Obscene, pornographic, and violent information that hurts
                      users; dignity.
                    </li>

                    <li className="pl-3">
                      Abusive, threatening, and discriminatory communication
                      promotes racism, sexism, and hatred.
                    </li>

                    <li className="pl-3">
                      The language promotes illegal activities like terrorism,
                      racial discrimination, and gender-based violence.
                    </li>

                    <li className="pl-3">
                      Information that relates to commercial activity like
                      advertisements, sales, and advertisements.
                    </li>

                    <li className="pl-3">
                      Information or files containing spyware, viruses, corrupt
                      data, and malicious codes can limit or impair the Apps
                      functionality.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* your content */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">“Your Content”</div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  Users are responsible and liable for
                 
                    
                    "your content"
                  
                  shared through or uploaded into the App. You will endorse,
                  defend, share and store “your content” as harmless from any
                  claims made by other users. Every user must be responsible for
                  the content they use in their App profiles. This condition is
                  important in discouraging people from engaging in
                  irresponsible online social behaviors.
                </p>

                <p>
                  LoveAfrica Inc. does not allow App users to share personal
                  identification and banking information through their profiles.
                  For instance, you cannot convey a physical address, emails,
                  URLs, credit and debit card details, and phone numbers to
                  other users.
                </p>

                <p>
                  LoveAfrica Inc. management reserves the right to terminate
                  accounts where users share self- identifying information. The
                  company does not take responsibility for any problems
                  associated with sharing personal information. The command
                  takes these strict measures to protect users from possible
                  online abuse and exploitation by cybercriminals.
                </p>

                <p>
                  Your content in the App will be accessible to other African
                  relationship-seekers. LoveAfrica connects young people from
                  over 50 African countries all over the world. Every user
                  should understand that people visiting the App will access
                  posted information. In this case, it becomes a good practice
                  for users to review their content before posting. Uploading
                  your content at LoveAfrica implies that you have proper rights
                  and licenses, which automatically grants our management a
                  non-exclusive authority to use the information. For example,
                  LoveAfrica Inc. management can copy, adapt, translate,
                  reformat, create directive content, incorporate it into other
                  information, advertise, and distribute your details and files.
                  Besides, LoveAfrica Inc. can avail your information publicly,
                  in whole, part, format, or medium. The company will regularly
                  review different forms of sharing information.
                </p>

                <p>
                  LoveAfrica Inc. reserves a right to share the license to use
                  “Your Content” with its affiliates and successors. In
                  practice, the company management does not require further
                  permission from users when sharing the information with
                  associates and successors. Besides, the direction can remove,
                  edit, block, or limit what users may upload or share using the
                  App without seeking consent. Finally, LoveAfrica Inc.
                  management is not obligated to display or review
                 
                    
                    your content.
                  
                </p>
              </div>
            </div>
          </div>

          {/* member content */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                “Member's Content”
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  Other members of LoveAfrica will share information and content
                  through the App. “Member Content” includes all content that
                  users transmit or post through the App. LoveAfrica Inc.
                  leadership can store
                 
                    Member Content
                  
                  on the companies or third-party servers.
                </p>

                <p>
                  Each member can give directions on how the information may
                  Appear on the App. You do not have the right to use or
                  manipulate “Member Content.” For instance, you may only use
                  users’ personal information to the extent that your handling
                  matches the key objective of allowing people to meet and
                  interact. You cannot utilize users' data for commercial
                  activities like advertising. LoveAfrica Inc. management
                  retains the authority to impose legal actions against members
                  who use profile information to engage in cybercriminal-related
                  activities like spamming, harassing, and making any form of
                  threats. Misuse of users; data can lead to immediate
                  termination of your account.
                </p>
              </div>
            </div>
          </div>

          {/* Our content */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">Our Content</div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  “Our Content” includes text, graphics, trademarks, logos, user
                  interface, sounds, and artwork used in the App. LoveAfrica
                  Inc. licenses and regulates
                 
                    Our Content.
                  
                  LoveAfrica Inc. management controls “Our Content” because it
                  is protected by copyright, trademark, and existing
                  intellectual property laws. Besides, the management retains
                  all rights, titles, and interests to
                 
                    Our Content.
                  
                </p>

                <p>
                  LoveAfrica management grants users a non-exclusive, personal,
                  limited, non-transferable, and revocable license to use “Our
                  Content.” Users do not have a right to sublicense “Our
                  Content” under the following circumstances.
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">
                      Users cannot use, modify, sell, or share “Our Content”
                      beyond what the App permits.
                    </li>

                    <li className="pl-3">
                      Users cannot use the company’s name in any form, even when
                      not using the App.
                    </li>

                    <li className="pl-3">
                      Users cannot create derivative work from “Our Content” or
                      sell any part.
                    </li>

                    <li className="pl-3">
                      Users can use “Our Content” for legally allowed purposes
                      only.
                    </li>

                    <li className="pl-3">
                      You are willing to comply with this agreement and other
                      laws Applicable to local and national laws Applicable in
                      your country.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* Socializing Guidelines */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Socializing Guidelines
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  Users who sign the terms and conditions agree to LoveAfrica
                  Inc. using emails and other means of communication to notify
                  and engage them with the agreed services. However, users must
                  conduct themselves with consummate dignity and
                  professionalism. LoveAfrica retains the authority to terminate
                  one’s membership. Every user should adhere to the following
                  LoveAfrica Inc. etiquette.
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">
                      You cannot share with other users defaming, inaccurate,
                      abusive, obscene,profane, offensive, demeaning, racially
                      and sexually offensive, or illegal content that can
                      infringe other people’s rights.
                    </li>

                    <li className="pl-3">
                      You cannot use manual or automatic devices to retrieve,
                      manipulate, reproduce, or edit people’s content or
                      personal information.
                    </li>

                    <li className="pl-3">
                      You cannot bypass the app’s navigational structure with
                      existing or upcoming technology.
                    </li>

                    <li className="pl-3">
                      You will not use the App to advance any business
                      activities.
                    </li>

                    <li className="pl-3">
                      You will not provide false information to LoveAfrica Inc.
                      during the registration of accounts.
                    </li>

                    <li className="pl-3">
                      You will not share log-in details with another individual.
                    </li>

                    <li className="pl-3">
                      All members should practice simple and acceptable privacy
                      and safety measures. For instance, a user cannot publish
                      or share any identifying personal contact information such
                      as complete names, physical addresses, email, instant
                      messaging details, and links to social media platforms.
                      These limitations are essential in promoting safety while
                      minimizing cases of cyberbullying.
                    </li>

                    <li className="pl-3">
                      Users who misuse the app's written space will face
                      disciplinary actions. The management retains the right to
                      terminate their accounts and restrict further use of the
                      App from IP addresses used previously by such individuals.
                    </li>

                    <li className="pl-3">
                      Members cannot use the information contained in the App to
                      disrupt public order or ongoing legal proceedings.
                    </li>

                    <li className="pl-3">
                      You must respect other users in your social network at
                      Love Africa. For instance, you must stop communicating
                      with other users upon their request. Failure to adhere to
                      such simple instructions from Love Africa Inc. members
                      will lead to immediate account termination.
                    </li>

                    <li className="pl-3">
                      Users must compensate LoveAfrica Inc. for claims and
                      damages that third parties may demand. LoveAfrica Inc.
                      management reserves the right to determine how a user’s
                      actions lead to specific claims or damages. In practice,
                      compensations may include legal fees arising from such
                      claims and damages. The management may give warnings or
                      suspend accounts that breach these terms and conditions to
                      prevent possible legal claims and damages.
                    </li>

                    <li className="pl-3">
                      LoveAfrica Inc. shall maintain a customer care team to aid
                      and guide users; However, every member should use polite
                      language when contacting customer care. LoveAfrica Inc.
                      management can terminate an account without warning if its
                      users harass or threaten employees or customer care
                      personnel. The company management has the irrevocable
                      right to investigate and remove an account without
                      refunding or reimbursing registration fees when users
                      violate terms of service. Other unacceptable behaviors
                      that may lead to disciplinary actions include misleading
                      LoveAfrica Inc. management.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="text-sm">
            <span className="rounded-md text-white px-2 py-1 bg-black mr-3">
              Last Updated:
            </span>
            16th October, 2022.
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  )
}

export default Home
