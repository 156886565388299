import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Hero from '../components/Hero'

export default function Error() {
  return (
    <Fragment>
      <Hero classes={{height:'100vh'}} BigText="404 Error!!"  smallText="The page you're looking for is not available. "/>
      <Link to="/">
      <div className=" flex justify-center w-full Bold text-2xl mt-12  " ><div className='bg-[#cc0000] rounded text-white px-3 py-1 cursor-pointer hover:bg-black'>  Go back </div></div> </Link>
    </Fragment>
  )
}
