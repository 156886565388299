import React, { Fragment } from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Hero'

function Policy() {
  return (
    <Fragment>
      <Hero BigText="Terms of Use"   />

      <div className="flex justify-center items-center my-8 md:my-12 ">
        <div className="flex-wrap justify-center items-center px-6 md:px-32 xl:px-64 space-y-12">
          {/* intro title  */}
          <div className="  flex  justify-center items-center pb-8">
            <div className=" pt-8 md:mt-2 space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-3xl">Terms of Use</div>

              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  When you download the App, you register an account. App users
                  must meet specific conditions to enhance their serviceability
                  and effectiveness. Before creating an account, you must be at
                  least 18 years old and a national identity card holder.
                  Besides, you should have legal permission to use the App by
                  the laws in your home country.
                </p>
              </div>
            </div>
          </div>

          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Use of Information
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  You can create a personal account through manual registration.
                  Other forms of registration include creating accounts through
                  third party Applications (Facebook and Google). In this semi-
                  automated registration process, you must authorize LoveAfrica
                  Inc. to access, display, and use information from your social
                  media accounts. Other information we may collect include;
                  Name, email address, mobile number, gender identity,
                  nationality, date of birth, sexual preference, passion and
                  photograph. After registration, you will be able to change
                  some information at any time you wish, this information
                  includes your sexuality and photograph.LoveAfrica does not
                  allow you to use another person’s account. The management
                  reviews how you use the app regularly to detect fraud cases.
                </p>
              </div>
            </div>
          </div>

          {/* Account Deactivation*/}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Account Deactivation
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  The management reserves the right to discontinue your account
                  without further notice or consultation. Such extreme measures
                  are necessary to curb possible cases of catfishing and
                  cyberbullying. Besides, LoveAfrica Inc. management can fail to
                  Approve an account registered by previous users engaged in
                  fraud incidents. The management reserves the right to suspend
                  any account or take legal measures to enforce terms and
                  conditions. Such operations may include restricting specific
                  Internet Protocol (IP) addresses from accessing accounts
                  registered on the App without giving prior notice.
                </p>
              </div>
            </div>
          </div>

          {/* account deletion */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Account Deletion
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  You can delete your account without facing additional
                  consequences. You can delete the LoveAfrica account from the
                  App setting button. However, you must be logged into your
                  account to access the “delete account” button in the App.
                  LoveAfrica Inc. saves your profile information for 30 days
                  after account deletion. This strategy makes it easy for you to
                  restore the account. LoveAfrica Inc. management reactivates
                  your account if you delete and try to open a new one within
                  thirty days. You will not use or access non-public areas of
                  the App. LoveAfrica Inc. reserves the ultimate right to
                  determine the areas different users can access at a specific
                  time. You will access certain App sections after registering,
                  confirming the account, and agreeing to terms of use.
                  Otherwise, you cannot use the App effectively to interact with
                  friends and followers.
                </p>
              </div>
            </div>
          </div>

          {/* Geolocation Information */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Geolocation Information
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  Your permission to your current device location allows us to
                  personalize your interest based on your device current
                  coordinates and this makes your dating experience soother and
                  easier with other users on the App.
                </p>
              </div>
            </div>
          </div>

          {/* User Safety */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">User Safety</div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. leadership does not conduct background checks
                  on registered users to determine their criminal history. The
                  management does not verify statements provided by users during
                  the account opening stage. However, the company can terminate
                  the users right to use the App after determining misleading
                  information during legal proceedings, disputes, or complaints
                  from third parties or other members. In this case, every
                  member is responsible for taking safety and precautionary
                  measures when using the App.
                </p>

                <p>
                  The management expects every user to demonstrate
                  responsibility when communicating with other members.
                </p>

                <p>
                  LoveAfrica Inc. leadership does not conduct background checks
                  on registered users to determine their criminal history. The
                  management does not verify statements provided by users during
                  the account opening stage. However, the company can terminate
                  the users right to use the App after determining misleading
                  information during legal proceedings, disputes, or complaints
                  from third parties or other members. In this case, every
                  member is responsible for taking safety and precautionary
                  measures when using the App. The management expects every user
                  to demonstrate responsibility when communicating with other
                  members.
                </p>

                <p>
                  Users should expect potential risks when interacting online
                  and offline. Dating online and arranging physical meetings
                  with other users can lead to potential security issues. Every
                  user should take responsibility for dating and social
                  activities. LoveAfrica Inc. management does not bear
                  responsibility for arising security issues. However, you
                  should avoid meeting people in insecure places. You should
                  only agree to meet strangers in public areas to minimize
                  security risks. LoveAfrica Inc. does not verify the accuracy
                  of users’ contact details. However, the management tries to
                  keep users’ profile information unaltered, complete, and
                  current.
                </p>

                <p>
                  Users should not derive any rights from this company’s role.
                  One should take the risk of relying on specific information
                  available through the App. The App may experience some
                  interruptions in its operations. For example, LoveAfrica Inc.
                  leadership will facilitate system upgrades regularly to ensure
                  seamless service delivery. Members may experience hitches
                  during scheduled maintenance. However, users will receive
                  maintenance notifications in advance to avoid possible
                  frustrations. Every member should demonstrate patience during
                  maintenance sessions since they are essential in promoting
                  overall security. Users should take responsibility for any
                  actions or activities during scheduled maintenance sessions.
                </p>

                <p>
                  Users should raise any security concerns to customer care.
                  Every member is responsible for promoting the app's security
                  and safety. Some issues that people should report include
                  slowed App functionality, verbal threats from other users, and
                  use of abusive language. The customer care team will provide
                  round-the-clock support to users. Besides, the management will
                  take prompt actions to resolve any security issues and
                  concerns raised.
                </p>

                <p>
                  Parents should take responsibility for their children’s usage
                  of the App. For example, LoveAfrica Inc. will not collect data
                  from minors regarding their age. Parents should inform
                  LoveAfrica Inc. when minors lie about their age. Adults have a
                  right to request LoveAfrica Inc. customers to delete details
                  provided by minors. Besides, the management will not verify
                  identification documents that users provide during the
                  registration stage. However, LoveAfrica Inc. will terminate
                  accounts upon establishing that provided information is fake
                  or inaccurate.
                </p>
              </div>
            </div>
          </div>

          {/* contains ads */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Contains Ads (Purchases, Returns, and Refunds)
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. users will have the freedom to leave ads on or
                  turn them off. Users can navigate through the app to use the
                  settings to cancel the ads. However, turning off the ads will
                  attract a fee. Nevertheless, such actions are necessary since
                  LoveAfrica Inc. depends on the ads as a source of revenue to
                  support its activities. Hence, customers have the autonomy to
                  manage the ads in their LoveAfrica Inc. depending on the
                  established terms and conditions of use. Successful payments
                  are non-refundable. For instance, customers will not receive
                  refunds, returns, or credits upon successful purchases.
                </p>

                <p>
                  In addition, a customer cannot cancel or reverse charges. In
                  this case, every new member should take precautions and
                  Appropriate deliberations when making payments. Finally,
                  customers cannot get refunds after possible account
                  termination. These policies are essential in protecting the
                  company’s financial Integrity.
                </p>

                <p>
                  Every user will have the responsibility of managing
                  subscriptions. For instance, the company will facilitate
                  monthly charges until users discontinue such payments
                  manually. The conditions and terms of service allow customers
                  to decide how they use their funds. The company supports
                  automatic subscriptions following the customer’s consent. In
                  this case, the company does not have the authority to
                  terminate a customer’s subscription. Users must discontinue
                  their subscriptions seven days before the following month to
                  stop payment successfully.
                </p>

                <p>
                  These actions are necessary for promoting ethical financial
                  management among Love Africa Inc. clients. However, in
                  practice, a customer’s failure to make proper and timely
                  subscription cancellations will attract charges for the
                  following month. Therefore, the company will only facilitate
                  timely subscription cancellations without imposing additional
                  charges or penalties.
                </p>

                <p>
                  Lastly, be aware that prices of subscription may increase over time and it is your responsibility to opt out in a timely manner to avoid any additional charges.
                </p>
              </div>
            </div>
          </div>

          {/* biometric */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Profile Verification
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                All users must go through a verification process. After initial verification the user will receive a blue check mark identifying the user is real. If a user wants to verify their identity even further they will need to send an email to contact@loveafrica.app.
                </p>
                <p> Once LoveAfrica reviews the account the upgrade will be made and the user will receive a gold badge. However, to receive a gold badge a cost of $5.99 will be deducted from your account every month. Please note that it is the user's responsibility to cancel payment subscription in a timely manner to avoid monthly charges. In addition, to get more information about payments and subscriptions please go back and read LoveAfrica terms and conditions thoroughly.
                </p>
                <p>
Please note if any payments made to LoveAfrica are disputed. That user will be banned from using LoveAfrica. In order to have access to LoveAfrica again, dispute charges must be credited back to LoveAfrica. If you have any problems, questions or concerns with certain charges that you have made please contact support@loveafrica.app.
</p>
              </div>
            </div>
          </div>

          {/* email notification */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Email Notification
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. will provide push notifications on the App
                  regarding new offers, products, and policies. Those who deny
                  the request will not receive notifications during their usage.
                  Besides, one may opt out of receiving information by changing
                  push message settings. The App allows members to subscribe to
                  or unsubscribe from other communications options like emails
                  and text messages.
                </p>
                <p>
                  LoveAfrica Inc. will allow users to access specific content
                  depending on location. The management will determine the
                  relevance of such information to users. The App uses Global
                  Positioning System (GPS) to access one’s area of residence. In
                  this case, disabling your device's GPS will result in low
                  functioning of the App, based on users preference. However,
                  users will provide consent on whether to receive such
                  notifications.
                </p>
              </div>
            </div>
          </div>

          {/* Device Information/Permissions */}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">
                Device Information/Permissions
              </div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica does not disclose users' registered information to
                  the public, although, we may collect information about your
                  device when you use the App. LoveAfrica can only have access
                  to the permissions you have granted the app. The permissions
                  include; Fingerprint, Audio, Location, Contacts,
                  Image-storage, Camera. We may use your registered information
                  for any of the various reasons:
                </p>

                <p>
                  <ul className="space-y-3 list-decimal pl-6">
                    <li className="pl-3">Provide quality ads experience.</li>

                    <li className="pl-3">
                      Personalize the content we deliver to you.
                    </li>

                    <li className="pl-3">Investigate illegal activities.</li>

                    <li className="pl-3">
                      Send you information about our products, new promotions.
                    </li>

                    <li className="pl-3">
                      Conduct specific research on how you use the app and
                      provide analytics.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* Disclaimer*/}
          <div className="  flex  justify-center items-center pb-8">
            <div className="  space-y-6 flex-wrap justify-center items-center ">
              <div className="text Bold font-bold text-2xl">Disclaimer</div>
              <div className="text-xl space-y-6 leading-loose  flex-wrap justify-center items-center">
                <p>
                  LoveAfrica Inc. does not provide an applied or expressed
                  warranty to any content available in the App. Users access the
                  content in the form presented in their Apps. However, the
                  management can provide a minimum express or implied warranty
                  when applicable laws fail to allow preceding exclusion. In
                  such instances, the administration will not provide guidance
                  or information to create a guarantee not provided in these
                  terms and conditions. LoveAfrica Inc. does not provide a
                  warrant that the App will be secure and free from errors.
                </p>
                <p>
                  Every member should take the risk of using the App. Besides,
                  members should be responsible for any social interactions with
                  other users. The company management, customer support
                  personnel, and employees are not responsible for users;
                  actions, behaviors, content, and language used on the site.
                  Instead, every member should take responsibility for their
                  behaviors even when such conducts lead to legal consequences
                  and interventions.
                </p>

                <p>
                  The management will not participate in any legal actions that
                  arise from irresponsible App use. The preceding disclaimer
                  remains relevant even when Love Africa Inc. receives advice on
                  possible damages. Users have a right to stop using the App if
                  they become dissatisfied with the services provided. However,
                  the disclaimer Applies to people from countries that have laws
                  that allow such provisions.
                </p>
              </div>
            </div>
          </div>

          <div className="text-sm">
            <span className="rounded-md text-white px-2 py-1 bg-black mr-3">
              Last Updated:
            </span>
            10th February, 2023.
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Policy
