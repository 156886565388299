import { Fragment } from 'react'
import * as image from './Images'

import { useState } from 'react'
import { Link } from 'react-router-dom'
import SideBar from './SideBar'

export default function TopNav() {
  // const [dropdown, setDropdown] = useState(false)

  // const handleDropdown = (e) => {
  //   e.preventDefault()
  //   setDropdown(!dropdown)
  // }

  const [sidebar, setSidebar] = useState(false);

  const checkNav = () => {
    setSidebar(!sidebar)
  };
  return (
    <Fragment>
      {sidebar ? <SideBar close={checkNav} /> : null}
      <nav
        className=" relative flex items-center justify-between flex-wrap  p-2 pt-3 md:px-24 "
        style={{ position: 'scroll', zIndex: 90 }}
      >
        <div className="flex items-center pt-3 flex-shrink-0 text-white md:mr-24">
          <Link to="/">

            {/* large screen */}
          <span className=" flex-shrink w-1/2   ">
              <img
                src={image.textWhites}
                width="150px"
                alt="LoveAfrica Logo"
                title="LoveAfrica Logo"
              />
            </span> 
          </Link>
        </div>
        

        {/* HAMBURGER */}
        <div className="block lg:hidden">
          <button
          onClick={checkNav}
            // onClick={handleDropdown}
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:bg-tranparent hover:border-white"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className="hidden lg:inline-block text-xl md:text-normal w-full   lg:flex   lg:items-center  justify-evenly lg:w-auto "
        >
          <div className=" lg:flex justify-evenly md:space-x-20 md:mr-12">
            <Link to="/">
              <div
                href="#responsive-header"
                className="regular responsive-header block cursor-pointer   lg:inline-block lg:mt-0 text-white hover:text-white/75 "
              >
                Home
              </div>
            </Link>
            <a href="https://loveafrica.app/about">
              <span
                href="#responsive-header"
                className="regular responsive-header block cursor-pointer  lg:inline-block lg:mt-0 text-white hover:text-white/75 "
              >
                About
              </span>
            </a>

            <a href="https://loveafrica.app/downloads">
            <span
              href="#responsive-header"
              className="regular responsive-header block cursor-pointer  lg:inline-block lg:mt-0 text-white hover:text-white/75"
            >
              Download
            </span>
            </a>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}
