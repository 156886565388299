import * as React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from '../pages/Home'
import Error from '../pages/Error'
import Policy from '../pages/Policy'




const Stack = () => {

 

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<Error />} />
          <Route path="/terms" element={<Policy />} />
        </Routes>

      </BrowserRouter>
    </React.Fragment>
  )
}

export default Stack
