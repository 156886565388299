// import { Link } from 'react-router-dom'
import * as image from './Images'


export default function Footer() {
  return (
    <>
      <footer class="" style={{ backgroundColor: '#121212' }}>
        <div
          style={{
            // position: 'absolute ',
            bottom: 0,
            backgroundColor: '#121212',
          }}
          class="  w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
        >
          <div class="lg:pl-32 xl:grid xl:grid-cols-3 xl:gap-8">
            <div class="xl:col-span-1">
              <img
                src={image.textWhites}
                className="w-28 md:w-32"
                alt="LoveAfrica Logo"
                title="LoveAfrica Logo"
              />
              <p class="  pt-4 text-white/75 text-sm md:text-base">
                Dare to meet your match!.
              </p>
              <div class="mt-8 flex">
                <a href="#" class="text-white hover:text-white/75">
                  <span class="sr-only">Facebook</span>
                  <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
                <a href="#" class="ml-6 text-white hover:text-white/75">
                  <span class="sr-only">Twitter</span>
                  <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="mt-12 flex w-full lg:ml-32  ">
              <div class="w-full flex justify-between  lg:space-x-12 ">
                <div class="flex-1">
                  <h4 class="text-sm font-semibold tracking-wider text-white uppercase">
                    LoveAfrica
                  </h4>
                  <ul class="mt-4">
                    <li class="mt-4">
                      <a
                         href="https://loveafrica.app/about"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        About
                      </a>
                    </li>
                    <li class="mt-4">
                      <a
                        href="#"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        Career
                      </a>
                    </li>
                    <li class="mt-4">
                      <a
                        href="#"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        Investors
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="flex-1">
                  <h4 class="text-sm font-semibold tracking-wider text-white uppercase">
                    Legal
                  </h4>
                  <ul class="mt-4">
                    <li class="mt-4">
                      <a
                        href="https://policy.loveafrica.app"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        Privacy
                      </a>
                    </li>
                    <li class="mt-4">
                      <a
                        href="https://policy.loveafrica.app/terms"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        Terms
                      </a>
                    </li>

                    <li class="mt-4">
                      <a
                        href="https://policy.loveafrica.app/cookies"
                        class="text-sm md:text-base text-white/75 hover:text-white/50"
                      >
                        Cookie Policy
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="flex-1">
                  <h4 class="text-sm font-semibold tracking-wider text-white uppercase">
                    Support
                  </h4>
                  <ul class="mt-4">
                      <li class="mt-4">
                        <a
                          href="https://loveafrica.app/contact"
                          class="text-sm md:text-base text-white/75 hover:text-white/50"
                        >
                          Contact Us
                        </a>
                      </li>

                      <li class="mt-4">
                        <a
                          href="https://loveafrica.app/Faq"
                          class="text-sm md:text-base text-white/75 hover:text-white/50"
                        >
                          FAQ
                        </a>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-12 border-t border-gray-200 pt-8">
            <p class="text-sm md:text-base text-white xl:text-center">
              © 2022 LoveAfrica App. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}
