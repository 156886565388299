import React from 'react'
// import * as image from '../components/Images'
import TopNav from './TopNav'
import { motion } from 'framer-motion'

function Hero({ BigText, smallText, classes}) {
  return (
    <>
      <div className="w-full absolute   ">
        <TopNav />
      </div>
      <div
        style={{ zIndex: 80,   }}
        class={classes}
        className=" img3  bg-gradient-to-bl from-red-700 to-black w-full flex md:px-12  "
      >
        <div class="w-full  justify-center items-center content-center flex  lg:pl-8 md:w-1/2">
          <div class="w-full lg:w-2/3  flex-wrap justify-center  items-center content-center space-y-12 px-4 text-center md:text-left text-white ">
            <div
              class="relative boxes "
              style={{ zIndex: 1, opacity: 1 }}
            ></div>
            <motion.div
              animate={{ y: 1 }}
              transition={{ delay: 0.5 }}
              initial={{ y: 150 }}
              class="w-full flex justify-center md:justify-start items-center text-5xl  lg:text-6xl font-bold bold  "
            >
              {BigText}
            </motion.div>
            <motion.div
              animate={{ y: 1 }}
              transition={{ delay: 0.9 }}
              initial={{ y: 100 }}
              class="text-xl md:text-2xl light"
            >
              {smallText}
            </motion.div>
          
          </div>
          <div class="hidden md:block lg:w-1/3">
            <div
              class="relative boxeses "
              style={{ zIndex: 1, opacity: 1 }}
            ></div>
          </div>
        </div>

        <div class=" w-full mt-12 md:w-1/2 flex   hidden md:inline-block lg:mr-6 ">
          <div class="flex h-screen justify-center items-center "> 
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
