import { Fragment } from "react";
import Stack from "./dir/stack/Stack";

function App() {
  return (
    <Fragment>
        <Stack />
    </Fragment>
  );
}

export default App;
